<template>
  <div class="main-page">
    <van-skeleton title avatar :row="3" :loading="skeletonLoading">
      <van-nav-bar title="订单详情" left-text="返回" left-arrow @click-left="handleClickLeft" />
      <van-row class="activity-info">
        <van-col span="10">
          <div class="cover">
            <img :src="orderData.activityCover" alt="" @click="toActivityDetail" />
          </div>
        </van-col>
        <van-col span="14">
          <div class="detail" @click="toActivityDetail">
            <h2 class="title van-multi-ellipsis--l2">{{ orderData.activityTitle }}</h2>
            <div class="descn van-multi-ellipsis--l2">{{ orderData.activityDescn }}</div>
          </div>
        </van-col>
      </van-row>

      <div class="order-base">
        <div class="order-item">
          <div class="label">订单编号：</div>
          <div class="value">{{ orderData.orderNum }}</div>
        </div>
        <div class="order-item">
          <div class="label">下单时间：</div>
          <div class="value">{{ orderData.createTime }}</div>
        </div>
        <div class="order-item">
          <div class="label">订单状态：</div>
          <div class="value">{{ orderData.orderStatus | formatOrderStatus }}</div>
        </div>
        <div class="order-item">
          <div class="label">支付方式：</div>
          <div class="value">在线支付</div>
        </div>
        <div class="order-item">
          <div class="label">支付时间：</div>
          <div class="value">{{ orderData.payedTime }}</div>
        </div>
        <div v-if="orderData.activityType === 1" class="order-item">
          <div class="label">团队角色：</div>
          <div class="value">{{ orderData.leaderFlag?'队长':'队员' }}</div>
        </div>
        <div v-if="orderData.activityType === 1" class="order-item">
          <div class="label">团队编号：</div>
          <div class="value">{{ orderData.teamId }}</div>
        </div>
      </div>
      <div class="order-amount">
        <div class="order-item">
          <div class="label">应付金额：</div>
          <div class="value">{{ orderData.needPayMoney | formatMoney }}</div>
        </div>
        <div class="order-item">
          <div class="label" />
          <div class="value"><span>实付款：</span><span>{{ orderData.consumeMoney | formatMoney }}</span></div>
        </div>
      </div>
      <van-goods-action v-if="orderData.orderStatus === 0">
        <van-goods-action-button type="primary" text="取消支付" @click="handleCancelOrder" />
        <van-goods-action-button type="danger" text="立即购买" @click="handleContinuePay" />
      </van-goods-action>
    </van-skeleton>
    <!--团购用的分享按钮-->
    <SharePoster v-if="orderData.orderStatus === 1 && orderData.activityType === 1" :team-id="orderData.teamId" :aid="orderData.activityId" atype="groupOn" />
    <!--分销用的分享按钮-->
    <SharePoster v-if="orderData.orderStatus === 1 && orderData.activityType === 0" :aid="orderData.activityId" atype="share" />
  </div>
</template>

<script>
import orderApi from '@/api/order'
import wxComPayApi from '@/api/wxcom-pay'
import wxconfig from '@/wx'

import SharePoster from '@/views/components/SharePoster.vue'
import { isOnline } from '@/utils/comm'

export default {
  components: {
    SharePoster
  },
  data() {
    return {
      skeletonLoading: true, // 骨架loading
      appId: '',
      orderNum: '',
      orderData: {}
    }
  },
  computed: {
    userInfo() {
      return this.$store.getters.userInfo
    }
  },
  created() {
    this.appId = this.$route.params.appId
    this.orderNum = this.$route.params.orderNum
    this.initData()
  },
  mounted() {},
  methods: {
    handleClickLeft() {
      this.$router.push(`/${this.appId}/ucenter/order/list`)
    },
    handleCancelOrder() {
      this.$dialog
        .confirm({
          title: '取消订单',
          message: '确定取消此订单吗？'
        })
        .then(() => {
          orderApi.cancel(this.orderNum).then(res => {
            if (res.succ) {
              this.initData()
            }
          })
        })
        .catch(() => {
          // on cancel
        })
    },
    handleContinuePay() {
      wxComPayApi.continuePay(this.orderNum).then(res => {
        if (res.succ) {
          let data = res.data
          this.$wx.chooseWXPay({
            appId: data.appId,
            timestamp: data.timeStamp,
            nonceStr: data.nonceStr,
            package: data.package,
            signType: data.signType,
            paySign: data.paySign,
            success: res => {
              // 支付成功后跳转，没啥用，点金计划
              // this.$router.replace({ path: '/' })
            },
            cancel: res => {
              // 用户取消支付
              console.log('用户取消：' + JSON.stringify(res))
            },
            fail(error) {
              console.log('支付报错!', JSON.stringify(error))
            }
          })
        }
      })
    },
    initData() {
      orderApi.detail(this.orderNum).then(res => {
        if (res.succ) {
          this.skeletonLoading = false
          this.orderData = res.data.item
          // 初始化微信相关
          this.initWxConfig()
        }
      })
    },
    initWxConfig() {
      // 配置微信信息
      if (isOnline()) {
        wxconfig([this.initShareData])
      }
    },
    toActivityDetail() {
      if (this.orderData.activityType === 0) {
        // 到分销活动
        let userInfoId = this.userInfo.id
        this.$router.push(`/${this.appId}/distribute/detail/${this.orderData.activityId}/${userInfoId}`)
      }
      if (this.orderData.activityType === 1) {
        // 到团购活动的加入页面
        this.$router.push(`/${this.appId}/groupOn/join/${this.orderData.activityId}/${this.orderData.teamId}`)
      } else {
        // 其它
        let userInfoId = this.userInfo.id
        this.$router.push(`/${this.appId}/distribute/detail/${this.orderData.activityId}/${userInfoId}`)
      }
    },
    initShareData() {
      // 配置分享信息
      let userInfoId = this.userInfo.id
      let title = this.orderData.activityTitle
      let desc = this.orderData.activityDescn
      let imgUrl = this.orderData.activityCover
      let link = ''
      if (this.orderData.activityType === 1) {
        link = process.env.VUE_APP_BASE_URL + `/${this.appId}/groupOn/join/${this.orderData.activityId}/${this.orderData.teamId}?ruId=${userInfoId}`
      }
      if (this.orderData.activityType === 0) {
        let userInfoId = this.userInfo.id
        link = process.env.VUE_APP_BASE_URL + `/${this.appId}/distribute/detail/${this.orderData.activityId}/${userInfoId}?ruId=${userInfoId}`
      }

      let shareOptions = {
        title: title,
        desc: desc,
        link: link,
        imgUrl: imgUrl
      }

      // 分享给好友
      this.$wx.updateAppMessageShareData(shareOptions)
      // 分享到朋友圈
      this.$wx.updateTimelineShareData(shareOptions)
    },

    customMenuItems() {
      // 隐藏分享按钮，
      this.$wx.hideMenuItems({
        menuList: ['menuItem:share:appMessage', 'menuItem:share:timeline', 'menuItem:share:qq', 'menuItem:share:weiboApp']
      })
      this.$wx.hideAllNonBaseMenuItem()
    }
  }
}
</script>

<style lang="less" scoped>
.activity-info {
  display: flex;
  justify-content: space-between;
  // align-items: center;
  background-color: #fff;
  margin-top: 8px;
  // border-radius: 10px;
  .cover {
    flex: 2;
    padding: 6px 4px 6px 6px;
    img {
      width: 100%;
      display: inline-block;
    }
  }
  .detail {
    flex: 3;
    padding: 4px;
    .title {
      margin: 2px 0 10px 0;
      font-size: 16px;
    }
    .descn {
      font-size: 12px;
      color: #999;
    }
  }
}
.order-amount {
  padding: 10px;
  font-size: 14px;
  background-color: #fff;
  margin-top: 8px;
  // border-radius: 10px;
  .order-item {
    height: 24px;
    line-height: 24px;
    display: flex;
    padding: 2px;
    margin-bottom: 4px;
    .label {
      flex: 1;
    }
    .value {
      flex: 1;
      text-align: right;
      span:last-child {
        color: red;
      }
    }
  }
}
.order-base {
  padding: 10px;
  font-size: 14px;
  background-color: #fff;
  margin-top: 8px;
  // border-radius: 10px;
  .order-item {
    height: 30px;
    border-bottom: 1px solid #efefef;
    line-height: 30px;
    display: flex;
    padding: 2px;
    margin-bottom: 4px;
    &:last-child {
      border-bottom: none;
    }
    .label {
      flex: 1;
    }
    .value {
      font-weight: bold;
      flex: 3;
    }
  }
}

.share_wrapper {
  position: absolute;
  right: 0;
  top: 36vh;
  display: block;
  background-color: darkred;
  color: #fff;
  height: 30px;
  line-height: 30px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  padding: 2px 2px 2px 6px;
  font-size: 14px;
  font-weight: bold;
}
.van-popup {
  width: 84%;
  min-height: 60vh;
  // overflow: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .van-loading {
    margin: 0 auto;
  }
  img {
    width: 100%;
    display: block;
  }
}
</style>
